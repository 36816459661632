import { type GetServerSideProps } from 'next'
import { type Session } from 'next-auth'
import { getServerSession } from 'next-auth/next'
import { v4 as generateUUID } from 'uuid'

import { getCMSContentModule } from '@/components/homepage/hero-cms-banner/getCMSContentModule'
import { HomepageInner } from '@/components/homepage/homepage-inner/HomepageInner'
import { getMenuPrefetch } from '@/components/homepage/mega-menu/getMenuPrefetch'
import { CookieRegistry } from '@/constants/cookieRegistry'
import { getLocale } from '@/domain/locale'
import { GetUserLocaleDocument, GetUserLocationDocument } from '@/generated/graphql-types'
import { addApolloState, initializeApollo } from '@/lib/apollo/apolloClient'
import profile from '@/lib/metrics/profileWrapper'
import { mergeSetCookieWithReqCookies } from '@/utils/cookies/mergeSetCookieWithReqCookies'
import { extractIpv4 } from '@/utils/extractIpv4'
import { serverSideTranslations } from '@/utils/serverSideTranslations'

import { getServerAuthOptions } from '../api/auth/[...nextauth]'

type HomeProps = {
  userAgent: string
  correlationId: string
  session: Session | null
}

/**
 * The homepage could be called by accessing `/`
 * or paths like: `/h-<location-seo-name>/<locationId>
 *
 * When there are parameters on the homepage path, then the user's location
 * should be switched to that defined location
 */
const Home = ({ correlationId, userAgent }: HomeProps) => {
  return <HomepageInner correlationId={correlationId} userAgent={userAgent} />
}

export const getServerSideProps: GetServerSideProps = profile<GetServerSideProps>(
  'homepage',
  'getServerSideProps',
  async (context) => {
    const gSSPstart = Date.now()
    const { req, res } = context

    const forwardedHeaders = {
      'user-agent': req.headers['user-agent'],
      'x-forwarded-for':
        req.headers['x-forwarded-for'] ?? extractIpv4(req.socket.remoteAddress ?? ''),
    }

    const mergedCookies = mergeSetCookieWithReqCookies(req.cookies, req.headers['set-cookie'], [
      CookieRegistry.USER_PREFERENCES,
    ])

    const requestHeaders = {
      headers: forwardedHeaders,
      cookies: mergedCookies,
    }

    const apolloClient = initializeApollo({ requestHeaders })
    const locale = apolloClient.readQuery({ query: GetUserLocaleDocument })
    const { routeLocale, cookieLocale } = getLocale(locale?.userLocale.routeLocale)

    const correlationId = generateUUID()
    const userAgent = req ? req.headers['user-agent'] || '' : navigator.userAgent
    const translations = await serverSideTranslations(routeLocale, ['home'])

    /**
     * Queries we want to pre-fetch on the server to load asap in the page
     * */
    const location = apolloClient.readQuery({ query: GetUserLocationDocument })
    const locationId = location?.userLocation.id
    await Promise.all([
      profile(
        'homepage',
        'getMenuPrefetch',
        getMenuPrefetch
      )({ apolloClient, cookieLocale, locationId }),
      profile('homepage', 'getCMSContentModule', getCMSContentModule)({ apolloClient, locationId }),
    ])

    const sessionStart = Date.now()
    const session = await profile('homepage', 'getServerSession', getServerSession)(
      req,
      res,
      getServerAuthOptions(req, res)
    )

    if (process.env.ENABLE_DEBUG === 'true') {
      const serverTimingHeader = res.getHeader('Server-Timing')

      res.setHeader(
        'Server-Timing',
        `${serverTimingHeader || ''}server-session;dur=${Date.now() - sessionStart},`
      )
    }

    if (process.env.ENABLE_DEBUG === 'true') {
      const serverTimingHeader = res.getHeader('Server-Timing')

      res.setHeader(
        'Server-Timing',
        `${serverTimingHeader || ''}server-session;dur=${Date.now() - sessionStart},`
      )
    }

    const pageProps: HomeProps = {
      correlationId,
      session,
      userAgent,
      ...translations,
    }

    const props = profile('homepage', 'addApolloState', addApolloState)(apolloClient, pageProps)

    if (process.env.ENABLE_DEBUG === 'true') {
      const serverTimingHeader = res.getHeader('Server-Timing')
      res.setHeader(
        'Server-Timing',
        `${serverTimingHeader || ''}server-props;dur=${Date.now() - gSSPstart},`
      )
    }

    return { props }
  }
)

export default Home
